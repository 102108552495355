import React from 'react'
import { useSearchParams } from "react-router-dom";


const Success = (props) => {
let today = new Date()
let date = today.getDate() + '-' + parseInt(today.getMonth() + 1) + '-' + today.getFullYear()
  const [searchParams, setSearchParams] = useSearchParams();
  var token = JSON.parse(atob(searchParams.get("token")));
  console.log("token :: " + atob(searchParams.get("token")));
  var message = token.message;
  console.log("message :: " + message);

  var amount;
  var tid;
  var status;
  var payment_id;
  var payment_mode;
  //var date;
  var category;
  var account_number;
  
    tid = atob(token.transectionId);
    amount = atob(token.receivePrice);
    account_number = atob(token.account_number);
    status = atob(token.status);
   

   
    console.log("Token :: " + atob(searchParams.get("token")));
    console.log(' transectionId :: ', atob(token.transectionId));
    console.log('receivePrice  :: ', atob(token.receivePrice));
    console.log('msisdn :: ', atob(token.account_number));
  

  
 

  return (
    <div>
        <div id="content">
  <div class="container">
    <div class="row ">
    
     
      <div class="col-lg-12 text-center mt-2">
        <p class="text-success text-16 lh-1"><i class="fas fa-check-circle"></i></p>
        
      </div>
      <div class="col-md-8 col-lg-6 col-xl-5 mx-auto">
        <div class="bg-white shadow-sm rounded p-4  mb-4">
        
          <div class="row">
            <div class="col-sm text-muted"> Transaction ID</div>
            <div class="col-sm text-sm-end fw-600">{tid}</div>
          </div>
       
          <hr />
          <div class="row">
            <div class="col-sm text-muted">Status</div>
            <div class="col-sm text-sm-end fw-600">{status}</div>
          </div>

          <hr />

        
          <div class="row">
            <div class="col-sm text-muted">Mobile No</div>
            <div class="col-sm text-sm-end fw-600">{account_number}</div>
          </div>
          <hr />
          
          <div class="row">
            <div class="col-sm text-muted">Payment Amount</div>
            <div class="col-sm text-sm-end  fw-600">{amount}</div>
          </div>
          
        </div>
        <div class="text-center">  
          <p class="mt-4 mb-0"><a href="https://play.google.com/store/apps/details?id=com.beeinnovations.zing" class="btn btn-primary">Reopen Your Zing App</a></p>
        </div>
      </div>
    </div>
  </div>
    </div>
    </div>
  )
}

export default Success
