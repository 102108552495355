import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Zing from './Zing'
import "bootstrap/dist/css/bootstrap.min.css";
// Bootstrap Bundle JS
import "bootstrap/dist/js/bootstrap.bundle.min"
import Success from './Success'


function App() {
  return (
    <>
     <BrowserRouter>
        
          <Routes>
            <Route exact path="/" element={<Zing/>} />
            <Route exact path="/Success"  element={<Success/>} />
                     </Routes>
        
      </BrowserRouter>
	 
    </>
  );
}

export default App;
