import React,{useState} from 'react';
import './zing.css';
import axios from 'axios';
import { useSearchParams,Link } from "react-router-dom";
const Zing = () => {
 
  const [searchParams, setSearchParams] = useSearchParams();
  const queryParameters = new URLSearchParams(window.location.search)
  const msisdn = queryParameters.get("msisdn")
	console.log(msisdn);
function loadScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement('script');
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }

async function displayRazorpay() {
const res = await loadScript(
      'https://checkout.razorpay.com/v1/checkout.js'
    );

    if (!res) {
      alert('Razorpay SDK failed to load. Are you online?');
      return;
    }  
    
//  var url ='http://172.22.9.77:8080/createorder';
//     const result = await 
//     axios({
//       method: 'post',
//       url: 'url',
//       data: {
//         "amount": 100
       
//       }
//     });

   const result = await axios.post('https://www.billwill.in:9922/createorder',{"amount":199});
   const sd= await JSON.stringify(result); 
   console.log(sd);
   if (!result) {
      alert('Server error. Are you online?');
      return;
    }

    const { amount, id, currency } = result.data;

    

    const options = {
      key: 'rzp_test_g2gsB4wf6npil0', // Enter the Key ID generated from the Dashboard
      amount: amount.toString(),
      currency: currency,
      name: 'BEE INNOVATIONS',
      description: 'Proceed  Transaction',
      image: 'https://www.beeinnovations.com/images/logo-full-white.png',
      id: id,
      handler: async function (response) {
        const data1 = {
          "msisdn":msisdn}

        const ok = await axios.post('https://www.billwill.in:9922/subscription', data1);

        alert(ok);
      },
      prefill: {
        name: 'Bee Innovations',
        email: 'BeeInnovations@gmail.com',
        contact: '9999999999',
      },
      notes: {
        address: 'Example Corporate Office',
      },
      theme: {
        color: '#61dafb',
      },
    };

    const paymentObject = new window.Razorpay(options);
    console.log(paymentObject);
    paymentObject.open();
  }

  return (
    <>
    <div className='container1'>
    <h3>Subscribe to Zing!</h3>

    <div className='ban-container'>
      
      <div>
	  {/*<h4>BSNL USERS</h4> */}
         <div className='nonbsnlcard'>
	 
                    <Link className="p-2" to="https://portal2.bsnl.in/myportal/tariffs.do?PREPAID"> <img src="/BSNLRL.jpg" alt="non" className='img'/></Link>

	  {/*	            <Link className="p-2" to="https://portal2.bsnl.in/myportal/quickrecharge.do"> <img src="/WhatsApp Image 2023-05-11 at 4.18.30 PM.jpeg" alt="non" className='img'/></Link>
          */} {/* <img src="/BNSL.jpeg" alt="non" className='img'/>

           <img src="/BNSL.jpeg" alt="non" className='img'/>
 
          <img src="/BNSL.jpeg" alt="non" className='img'/> */}

       
	 </div>

      </div>
	  {/*      <div className="mt-5">
         <h4>NON BSNL USERS</h4>
         <div className='bsnlcard'>
         <Link  className="p-2" to="https://play.google.com/store/apps/details?id=com.beeinnovations.zing">  <img src="/WhatsApp Image 2023-05-11 at 4.18.30 PM(1).jpeg"  onClick={displayRazorpay} alt="non" className='img' /> </Link>
          
	  {/* <Link  className="p-2" href="https://play.google.com/store/apps/details?id=com.beeinnovations.zing">  <img src="/WhatsApp Image 2023-05-11 at 4.18.30 PM(1).jpeg"  onClick={displayRazorpay} alt="non" className='img' /> </Link> */}

	  {/* <img src="/Zing 499.jpg" alt="non" className='img'/> 
          <img src="/Zing 499.jpg" alt="non" className='img'/> 
          <img src="/Zing 499.jpg" alt="non" className='img'/>  */}
	  {/*
         </div>
	   

      </div>*/}
    </div>
      </div>
    </>
  )
}
export default Zing

